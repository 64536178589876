<script setup>

  import NavbarComponent from '../components/NavbarComponent.vue'
  import SidebarComponent from '../components/SidebarComponent.vue'
  import { defineProps, ref, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import axios from 'axios';
  import router from '@/router';

  const store = useStore()

  const props = defineProps({
    sn: String
  })

  var form  = reactive({
    serialNo: props.sn,
    companyName: "",
    contactNo: "",
    contactName: "",
    csrEmailAddress: "",
    salesOrderNumber: "",
    salesOrderLine: "",
    address: "",
    customerMark: "",
    pressModel: "",
    pressMachMfgrName: "",
    quantity: "",
    poNumber: "",
    standardItemDesc: "",
    productFamily: "",
    cavityShape: "",
    cornerRadius: "",
    labelSizeAround: "",
    pressWidth: "",
    calcSpaceAround: "",
    spaceAcross: "",
    numCavitiesAround: "",
    numCavitiesAcross: "",
    labelSizeAcross: "",
    username: store.getters.stateUser ? store.getters.stateUser.username : "",
    comments: "",
    shippingInstructions: "",
    customerNo: ""
  })

  var roto_username = ref(null)
  var roto_password = ref(null)
  var loginToken = ref(null)
  var apiUsernamePassword = ref(null)
  var isSingleAddress = ref(true)
  var addressOptions = ref([])
  

  console.log("sn is ",props.sn)

  async function isConnected() {
    var currentURL = window.location.origin + ":5000/"
    var returnData = null
    try{
      await store.dispatch('viewMe',currentURL)
      returnData = 200
    
    }catch(err) {
      console.log("error in catch:",err)
      console.log("here1 in sidebar",store.getters.isAuthenticated)
      returnData = 401
      router.push({ name: 'Home'})
    }

    return returnData
  }

  async function getRotoLoginDetails(){

    var returnData = null

    var userDetails = store.getters.stateUser
    console.log("details in rot",user_details)
    if(userDetails != null){
      var user_details
      await axios.post('get_roto_login_details', { "id": userDetails.id})
        .then(response => (
          console.log("response in getRotoLoginDetails",response),
          user_details = response
        )
      )

      if(user_details.status == 200){
        roto_username.value = user_details.data.user_roto_username
        roto_password.value = user_details.data.user_roto_password

        returnData = user_details.status
      }
      else{
        returnData = 401
      }  
    }

    return returnData
    
  }

  async function customerLogin(){

    var returnData = null

    apiUsernamePassword.value = atob(roto_username.value) + ":" + atob(roto_password.value)
    apiUsernamePassword.value = JSON.stringify(btoa(apiUsernamePassword.value))
    await axios.post('https://rotoscanapideploy.azurewebsites.net/api/customer/login', apiUsernamePassword.value, { headers: { "Content-Type": "application/json" }, withCredentials: false })
      .then(response => (
        console.log("response in customerLogin",response),
        loginToken.value = response.data.token,
        form.contactNo = response.data.contactNumber,
        form.contactName = response.data.contactName,
        form.csrEmailAddress = response.data.csrEmailAddress,
        returnData = response.status
      )
    ).catch((error) => {
        if (error) {
          console.log("error in customerLogin",error)
          returnData = error.response.status
          if(error.response.status == 401){
            console.log("here in 401")
            router.push({ name: 'ManageProfile'})
          }
        }
      })

      return returnData
  }


    async function getCustomerOrderDetails(){
      var returnData = null
      await axios.post('get_order_details', { serial_number: props.sn })
        .then(response => (
          console.log("response in getCustomerOrderDetails",response),
          form.salesOrderNumber = response.data.order_number,
          form.salesOrderLine = response.data.line_number,
          form.customerNo = response.data.customer_number,
          returnData = response.status
        )
      ).catch((error) => {
          if (error) {
            console.log("error in getCustomerOrderDetails",error)
            returnData = error.response.status
            if(error.response.status == 400){
              router.push({ name: 'Maintenance'})
            }
          }
        })

        return returnData
    }



    async function getQuote(){
      var returnData = null
      var token = `Bearer ${loginToken.value}`
      await axios.get(`https://rotoscanapideploy.azurewebsites.net/api/quote/specification/${form.salesOrderNumber}/${form.salesOrderLine}`, { headers: { "Authorization": token, "Content-Type": "application/json" }, withCredentials: false })
        .then(response => (
          console.log("response in getQuote",response),
          form.customerMark = response.data.customerMark,
          form.pressModel = response.data.pressModel,
          form.pressMachMfgrName = response.data.pressMachMfgrName,
          form.quantity = response.data.quantity,
          // form.poNumber = response.data.customerPoNumber,   //leaving it empty to be filled by customer
          form.standardItemDesc = response.data.standardItemDesc,
          form.productFamily = response.data.pressModel,
          form.cavityShape = response.data.cavityShape,
          form.cornerRadius = response.data.cornerRadius,
          form.labelSizeAround = response.data.sizeAround,
          form.pressWidth = response.data.fdDesiredPlateWidth,
          form.calcSpaceAround = response.data.calcSpaceAround,
          form.spaceAcross = response.data.spaceAcross,
          form.numCavitiesAround = response.data.numCavitiesAround,
          form.numCavitiesAcross = response.data.numCavitiesAcross,
          form.labelSizeAcross = response.data.sizeAcross,
          returnData = response.status
        )
        
        
      ).catch((error) => {
          if (error) {
            console.log("error in getQuote",error)
            returnData = error.response.status
            if(error.response.status == 401){
              alert("Could not fetch previous quote please contact Support");
              router.push({ name: 'ManageProfile'})
            }
          }
        })

        return returnData
    }



    async function getAddress(){
      var returnData = null
      var addressData = []
      var token = `Bearer ${loginToken.value}`
      await axios.get(`https://rotoscanapideploy.azurewebsites.net/api/customer/address/${form.customerNo}`, { headers: { "Authorization": token, "Content-Type": "application/json" }, withCredentials: false })
        .then(response => (
          console.log("response in getAddress",response),
          addressData = response.data,
          returnData = response.status
        )
        
        
      ).catch((error) => {
          if (error) {
            console.log("error in getAddress",error)
            returnData = error.response.status
            if(error.response.status == 401){
              router.push({ name: 'ManageProfile'})
            }
          }
        })

        if(addressData.length){
          if(addressData.length == 1){
            if (addressData[0]["addressLine2"]==null){
              addressData[0]["addressLine2"] = "";
            }
            form.address = (addressData[0]["addressLine1"] + " " + (addressData[0]["addressLine2"] ? addressData[0]["addressLine2"] + " " : "") +
            addressData[0]["city"] + ", " + addressData[0]["state"] + " " + addressData[0]["postalCode"] + " " +
            addressData[0]["country"]).trim()
          }
          else{
            isSingleAddress.value = false
            addressData.forEach(function(value, index) {
            if (value["addressLine2"]==null){
              value["addressLine2"] = "";
            }
            
            var prettyAddress = (value["addressLine1"] ? value["addressLine1"] + " " : "") +
                (value["addressLine2"] ? value["addressLine2"] + " " : "") +
                (value["city"] ? value["city"] + ", " : "") +
                (value["state"] ? value["state"] + " " : "") +
                (value["postalCode"] ? value["postalCode"] + " " : "") +
                (value["country"] ? value["country"] : "");

            addressOptions.value.push({ "address": prettyAddress, "idx": index })

          });
          }
          form.companyName = addressData[0]["shipToCustomer"]
        }

        return returnData
    }


    const onSubmit = async (event) => {
        event.preventDefault()
        console.log("form",form)
        form.comments = form.comments + `  Purschased by - ${store.getters.stateUser.username} `;   


        await axios.post('send_purchase_data', form)
        .then(response => (
                console.log("response in send_purchase_data",response)
            )
        )
    }



  onMounted(async () => {
    var code = 200
    code =  await isConnected();
    if(code == 400 || code == 401){
      return
    }
    
    code = await getRotoLoginDetails();
    if(code == 400 || code == 401){
      return
    }

    code = await customerLogin();
    if(code == 400 || code == 401){
      return
    }
    
    code = await getCustomerOrderDetails();
    if(code == 400 || code == 401){
      return
    }

    code = await getQuote();
    if(code == 400 || code == 401){
      return
    }

    code = await getAddress();
    if(code == 400 || code == 401){
      return
    }
  })
  

</script>


<template>
  <BContainer fluid>
    <BRow>
      <NavbarComponent :sideBarEnable="true" />
    </BRow>
    <BRow>
      <BCol>
        <SidebarComponent />
          <div class="main-expand" id="main">
            <div class="card settings-card">
              <div class="form-wrapper">
                <BForm  @submit="onSubmit">
                    <div class="row">
                      <div class="col-sm-6 text-start">
                        <img class="logo" src="../assets/images/myRoto.svg" height="55" alt="Rotometrics + MyRoto">
                      </div>
                      <div class="col-sm-6 text-end d-flex align-items-center justify-content-end">
                        <h3 class="text-end" style="margin-bottom: 0px;">MyRoto Order Confirmation</h3>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-12">
                        <hr>
                        <h3>Customer Info</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="shipToCustomer" class="col-sm-5 col-form-label">Company Name</label>
                          <div class="col-sm-7">
                            <input type="text" v-model="form.companyName" class="form-control-plaintext" readonly id="shipToCustomer">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="username" class="col-sm-5 col-form-label">Purchaser Name</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="username" v-model="form.username">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="Address" class="col-sm-5 col-form-label">Address</label>
                          <div class="col-sm-7" id="AddressHolder">
                            <input  v-if="isSingleAddress" type="text" v-model="form.address" class="form-control-plaintext" id="Address" name="Address">
                            <select v-else v-model="form.address" required name="Address" id="Address" class="form-select form-control">
                              <option selected >Select an address</option>
                              <option v-for="addr in addressOptions" :key="addr.idx" :value="addr.address" >{{ addr.address }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="poNumber" class="col-sm-5 col-form-label">PO Number</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" name="poNumber" id="poNumber" v-model="form.poNumber">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-12">
                        <hr>
                        <h3>Die Info</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="serialNumber" class="col-sm-5 col-form-label">Die Serial Number</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="serialNumber" v-model="form.serialNo">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="quantity" class="col-sm-5 col-form-label">Quantity</label>
                          <div class="col-sm-7">
                            <input type="number" name="Quantity" min="1" required class="form-control" id="quantity" v-model="form.quantity">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="customerMark" class="col-sm-5 col-form-label">Die Mark</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="customerMark" v-model="form.customerMark">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="productFamily" class="col-sm-5 col-form-label">Product Family</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="productFamily" v-model="form.productFamily">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="standardItemDesc" class="col-sm-5 col-form-label">Product Family Type</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="standardItemDesc" v-model="form.standardItemDesc">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="cavityShape" class="col-sm-5 col-form-label">Label Shape</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="cavityShape" v-model="form.cavityShape">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="cornerRadius" class="col-sm-5 col-form-label">Label Corner Radius</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="cornerRadius" v-model="form.cornerRadius">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="labelSizeAcross" class="col-sm-5 col-form-label">Label Size Across</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="labelSizeAcross" v-model="form.labelSizeAcross">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="labelSizeAround" class="col-sm-5 col-form-label">Label Size Around</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="labelSizeAround" v-model="form.labelSizeAround">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="numCavitiesAcross" class="col-sm-5 col-form-label">Label Across</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="numCavitiesAcross" v-model="form.numCavitiesAcross">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="numCavitiesAround" class="col-sm-5 col-form-label">Label Around</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="numCavitiesAround" v-model="form.numCavitiesAround">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="spaceAcross" class="col-sm-5 col-form-label">Label Space Across</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="spaceAcross" v-model="form.spaceAcross">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="calcSpaceAround" class="col-sm-5 col-form-label">Label Space Around</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="calcSpaceAround" v-model="form.calcSpaceAround">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="pressMachMfgrName" class="col-sm-5 col-form-label">Press Manufacturer</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="pressMachMfgrName" v-model="form.pressMachMfgrName">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="pressModel" class="col-sm-5 col-form-label">Press Model</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="pressModel" v-model="form.pressModel">
                          </div>
                        </div>
                      </div>
                    </div>


                  <div class="row">
                  <div class="col-sm-4">
                    <div class="row mb-3">
                      <label for="comments" class="col-sm-5 col-form-label">Comments</label>
                      <div class="col-sm-7">
                        <input type="text" class="form-control" name="comments" id="comments" v-model="form.comments">
                      </div>
                    </div>
                  </div>


                  <!--    <div class="row">
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="pressWidth" class="col-sm-5 col-form-label">Press Width</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control-plaintext" readonly id="pressWidth" value="" v-model="form.pressWidth">
                          </div>
                        </div>
                      </div> -->
                      <div class="col-sm-4">
                        <div class="row mb-3">
                          <label for="shippingInstructions" class="col-sm-5 col-form-label">Shipping Instructions</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" name="shippingInstructions" id="shippingInstructions" v-model="form.shippingInstructions">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row"><div class="col-sm-12"><hr></div></div>
                    <div class="row">
                      <div class="col-sm-12 text-center">
                        <button type="submit" name="outputSubmit" id = "outputSubmit" class="btn btn-primary">Submit</button>
                      </div>
                    </div>
                    <div class="row"><div class="col-sm-12"><hr></div></div>
                    <div class="row">
                      <div class="col-sm-12 text-center">
                        <small>RotoMetrics® Trademark / Copyright 2018 Patent Pending / All Rights Reserved.</small>
                      </div>
                    </div>
                  </BForm>
                </div>
              </div>
            </div>
      </BCol>
    </BRow>
    
  </BContainer>


</template>